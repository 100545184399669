<template>
	<div>
		<div class="row">
	  		<div class="col-md-12 mr-auto ml-auto" v-if="costRegistrationLoaded">
				<card :title="$t('sidebar.costReport')">
					<base-input v-if="!showProjectDetails">
						<el-input
							type="search"
							class="mb-3 search-input from-group"
							clearable
							prefix-icon="el-icon-search"
							:placeholder="$t('common.searchRecords')"
							v-model="searchQuery"
							aria-controls="datatables"
						>
						</el-input>
					</base-input>
					<div class="calendar d-flex align-items-center">
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.from')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="fromDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
						<span class="mx-2">-</span>
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.to')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="toDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
					</div>
					<template>
						<div v-if="!showProjectDetails">
							<div v-if="!isMobile">
								<el-table :data="groupedReservations.projectGroups" @row-click="expandProject">
									<el-table-column prop="projectName" :label="$t('common.project')" min-width="150"/>
									<el-table-column prop="totalAmount" :label="$t('common.totalAmount')" min-width="50">
										<template slot-scope="scope">
											{{ formatNumber(scope.row.totalAmount) }}
										</template>
									</el-table-column>
									<el-table-column prop="totalAmountWithVAT" :label="$t('common.totalAmountVAT')" min-width="50">
										<template slot-scope="scope">
											{{ formatNumber(scope.row.totalAmountWithVAT) }}
										</template>
									</el-table-column>
								</el-table>
								<el-table v-if="groupedReservationsCalculated" :data="groupedReservations">
									<el-table-column :label="$t('common.totalAmount')" min-width="150" />
									<el-table-column :label="formatNumber(groupedReservations.totalAmount)" min-width="50" />
									<el-table-column :label="formatNumber(groupedReservations.totalAmountWithVAT)" min-width="50"/>
								</el-table>
							</div>
							<div v-else>
								<el-table :data="groupedReservations.projectGroups" @row-click="expandProject" >
									<el-table-column :label="$t('common.info')" min-width="150">
										<template slot-scope="scope">
											<div>
												<div><strong>{{$t('common.project')}}:</strong> {{ scope.row.projectName }}</div>
												<div><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalAmount) }}</div>
												<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalAmountWithVAT) }}</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(groupedReservations.totalAmount) }}</div>
								<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(groupedReservations.totalAmountWithVAT) }}</div>
							</div>
						</div>
						<div v-if="showProjectDetails">
							<card :title="selectedProject.projectName" :onCancel="collapseProject">
								<div class="project-header">
									<h5>{{ $t('common.totalAmount') }} : {{ formatNumber(selectedProject.totalAmount) }} kr</h5>
									<h5>{{ $t('common.totalAmountVAT') }} : {{ formatNumber(selectedProject.totalAmountWithVAT) }} kr</h5>
								</div>
								<el-table v-if="!isMobile" :data="selectedProject.reservations">
									<el-table-column :label="$t('tools.tools')">
										<template slot-scope="scope">
											#{{scope.row.tool.customerToolId}} {{scope.row.tool.name}}
										</template>
									</el-table-column>
									<el-table-column 
										:label="$t('tools.reservation')"
									>
										<template slot-scope="scope">
											<div>
												{{ setupDateRange(scope.row.reservedTimestamp, scope.row.unreservedTimestamp, scope.row.dateRange) }}
											</div>
											<div v-if="scope.row.rented">
												{{ $t('tools.rented') }}: {{ scope.row.externalUser }}
											</div>
										</template>
									</el-table-column>
									<el-table-column :min-width="60" :max-width="60" prop="dailyPrice" :label="$t('tools.dailyPrice')">
										<template slot-scope="scope">
											<div>{{ formatNumber(scope.row.dailyPrice) }} </div>
											<div v-if="scope.row.quantity !== null">{{$t('tools.quantity')}}: {{ scope.row.quantity }}</div>
										</template>
									</el-table-column>
									<el-table-column :label="$t('common.amount')">
										<template slot-scope="scope">
											<div v-if="scope.row.unreservedTimestamp === null" class="text-info">{{ formatNumber(scope.row.totalPrice) }} ({{$t('tools.reserved')}})</div>
											<div v-else-if="scope.row.parentReservationId" class="text-warning">{{ formatNumber(scope.row.totalPrice) }} ({{$t('common.subReservation')}})</div>
											<div v-else-if="!scope.row.totalPriceOverridden">{{ formatNumber(scope.row.totalPrice) }}</div>
											<div v-else class="text-danger">{{ formatNumber(scope.row.totalPrice) }} ({{$t('common.updated')}})</div>
										</template>
									</el-table-column>
									<el-table-column :label="$t('common.amountVAT')">
										<template slot-scope="scope">
											<div v-if="scope.row.unreservedTimestamp === null" class="text-info">{{ formatNumber(scope.row.totalPriceWithVAT) }} ({{$t('tools.reserved')}})</div>
											<div v-else-if="scope.row.parentReservationId" class="text-warning">{{ formatNumber(scope.row.totalPriceWithVAT) }} ({{$t('common.subReservation')}})</div>
											<div v-else-if="!scope.row.totalPriceOverridden">{{ formatNumber(scope.row.totalPriceWithVAT) }}</div>
											<div v-else class="text-danger">{{ formatNumber(scope.row.totalPriceWithVAT) }} ({{$t('common.updated')}})</div>
										</template>
									</el-table-column>
									<el-table-column :min-width="40" :max-width="40" align="right" :label="$t('common.actions')">
										<div slot-scope="props">
											<base-button @click.native.stop="handleReservationEdit(props.$index, props.row)" class="edit btn-link" type="warning" size="sm" icon>
												<i class="tim-icons icon-pencil"></i>
											</base-button>
										</div>
									</el-table-column>
								</el-table>
								<el-table v-else :data="selectedProject.reservations">
									<el-table-column :label="$t('common.info')" min-width="150">
										<template slot-scope="scope">
											<div>
												<div><strong>{{$t('tools.tools')}}:</strong> #{{scope.row.tool.customerToolId}} {{scope.row.tool.name}}</div>
												<div><strong>{{$t('tools.reservation')}}:</strong> {{ setupDateRange(scope.row.reservedTimestamp, scope.row.unreservedTimestamp, scope.row.dateRange) }} </div>
												<div v-if="scope.row.rented"><strong>{{$t('tools.rentedTo')}}:</strong> {{ scope.row.externalUser }}</div>
												<div><strong>{{$t('tools.dailyPrice')}}:</strong> {{ formatNumber(scope.row.dailyPrice) }}</div>
												<div v-if="scope.row.quantity !== null"><strong>{{$t('tools.quantity')}}:</strong> {{ scope.row.quantity }}</div>
												<template v-if="scope.row.unreservedTimestamp === null">
													<div class="text-info"><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalPrice) }}*</div>
													<div class="text-info"><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalPriceWithVAT) }}*</div>
													<div class="text-info"><strong>{{$t('tools.reserved')}}:</strong> {{$t('common.yes')}} </div>
												</template>
												<template v-else-if="scope.row.parentReservationId">
													<div class="text-warning"><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalPrice) }}*</div>
													<div class="text-warning"><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalPriceWithVAT) }}*</div>
													<div class="text-warning"><strong>{{$t('common.subReservation')}}:</strong> {{$t('common.yes')}} </div>
												</template>
												<template v-else-if="!scope.row.totalPriceOverridden">
													<div><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalPrice) }}</div>
													<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalPriceWithVAT) }}</div>
												</template>
												<template v-else>
													<div class="text-danger"><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalPrice) }}*</div>
													<div class="text-danger"><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalPriceWithVAT) }}*</div>
													<div class="text-danger"><strong>{{$t('common.updated')}}:</strong> {{$t('common.yes')}} </div>
												</template>
												<div>
													<base-button @click.native.stop="handleReservationEdit(scope.$index, scope.row)" class="edit btn-link" type="warning" size="sm" icon>
														<i class="tim-icons icon-pencil"></i>
													</base-button>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<base-button class="download-button" type="standard" @click="generatePDF(selectedProject)">
									{{ $t('common.download') }}
								</base-button>
							</card>
						</div>
					</template>
					<modal :show.sync="showUpdateReservationDaysModal" class="white-content">
					<h3 class="card-title">{{$t('tools.updateReservation')}}</h3>
					<div class="row">
						<div class="col-12">
						<label>{{$t('common.changeDates')}}</label>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
						<div class="calendar d-flex align-items-center">
							<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.from')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="updatedReservationDate"
								@change="updateTotalPrice"
							>
							</el-date-picker>
							</div>
							<span class="mx-2">-</span>
							<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.to')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="updatedUnreservationDate"
								@change="updateTotalPrice"
							>
							</el-date-picker>
							</div>
						</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
						<base-checkbox v-model="updatePrice">
							{{$t('common.updateTotalAmountVAT')}}
						</base-checkbox>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
						<base-input
							:disabled="!updatePrice"
							:placeholder="$t('common.totalAmountVAT')"
							v-model="updatedTotalPrice"
						>
						</base-input>
						</div>
					</div>
					<div class="row">
						<div class="col-sm d-flex justify-content-end">
						<base-button @click="handleReservationUpdate" type="standard" fill>
							{{$t('common.submit')}}
						</base-button>
						</div>
					</div>
					</modal>
				</card>
			</div>
		</div>
	</div>
</template>
  
<script>

import api from "../../services/api"
import { Table, TableColumn, DatePicker } from 'element-ui';
import { Modal } from 'src/components';
import { jsPDF } from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import swal from 'sweetalert2';

export default {
	components: {
		[DatePicker.name]: DatePicker,
		[Table.name]: Table,
    	[TableColumn.name]: TableColumn,
		Modal
	},
	data() {
		return {
			activeNames: ['1'],
			toDate: null,
			fromDate: null,
			updatedReservationDate: null,
			updatedUnreservationDate: null,
			isSubReservation: false,
			parentReservationId: null,
			updatedTotalPrice: null,
			dailyPriceForUpdate: null,
			quantityForUpdate: null,
			groupedProjects: [],
			reservationData: [],
			selectedProjectId: null,
			customerInformation: {},
			costRegistrationLoaded: false,
			groupedReservationsCalculated: false,
			searchQuery: '',
			isMobile: window.innerWidth <= 768,
			showProjectDetails: false,
			showUpdateReservationDaysModal: false,
			updateDays: '',
			latestUnreservedId: '',
			latestUnreservedEntity: 'ToolReservation',
			expandedProject: null,
			activeIndex: '',
			updatePrice: false,
		};
	},
	computed: {
		groupedReservations() {
			// Parse the selected period.
			const selectedStart = new Date(this.fromDate);
			const selectedEnd = new Date(this.toDate);
			
			const grouped = this.reservationData;

			// Rename the project named "RentProject" to the localized string.
			const rentProject = grouped.projectGroups.find(
				(group) => group.projectName === "RentProject"
			);

			if (rentProject) {
				rentProject.projectName = this.$t("tools.rented");
			}
			function processGroup(group) {
				// Consider only parent reservations (parentReservationId = null)
				const parents = group.reservations.filter((r) => !r.parentReservationId);
				let adjustedReservations = [];

				const dateOnly = (date) => {
					return new Date(date.split('T')[0]);
				};

				const formatDate = (date) => {
					const d = new Date(date);
					const day = ('0' + d.getDate()).slice(-2);
					const month = ('0' + (d.getMonth() + 1)).slice(-2);
					const year = d.getFullYear();
					return `${day}/${month}/${year}`;
				};

				// Helper to add days
				const addDays = (date, days) => {
					const d = new Date(date);
					d.setDate(d.getDate() + days);
					return d;
				};

				// Helper to subtract days
				const subtractDays = (date, days = 1) => {
					const d = new Date(date);
					d.setDate(d.getDate() - days);
					return d;
				};

				// Helper to compute the “gaps” in the effective period not covered by subreservations.
				const computeDateGaps = (effectiveStart, effectiveEnd, subs) => {
					let intervals = [];
					// Sort subreservations by start date.
					subs = subs.slice().sort((a, b) => dateOnly(a.reservedTimestamp) - dateOnly(b.reservedTimestamp));
					let pointer = new Date(effectiveStart);

					subs.forEach((sub) => {
					// Clamp each subreservation's start and end to the parent's effective period.
						let subStart = dateOnly(sub.reservedTimestamp);
						if (subStart < effectiveStart) { 
							subStart = new Date(effectiveStart);
						}
						let subEnd = sub.unreservedTimestamp ? dateOnly(sub.unreservedTimestamp) : effectiveEnd;
						if (subEnd > effectiveEnd) {
							subEnd = new Date(effectiveEnd);
						}
						// If there is a gap before the subreservation starts, record it.
						if (pointer < subStart) {
							// End gap one day prior to subStart (inclusive)
							const gapEnd = subtractDays(subStart, 1);
							if (pointer <= gapEnd) {
								intervals.push(`${formatDate(pointer)} - ${formatDate(gapEnd)}`);
							}
						}
						// Advance pointer to the day after subEnd.
						pointer = addDays(subEnd, 1);
					});
					// If there is a remainder after the last sub, add the final gap.
					if (pointer <= effectiveEnd) {
						intervals.push(`${formatDate(pointer)} - ${formatDate(effectiveEnd)}`);
					}
					return intervals;
				};

				parents.forEach((parent) => {
					// Parent info to calculate the new price
					const parentDailyPrice = Number(parent.dailyPrice);
					const parentDays = Math.round(Number(parent.totalPriceWithVAT) / parentDailyPrice);
					// Get all subreservations attached to this parent.
					const subs = parent.rented ? 
						group.reservations.filter((r) => r.parentReservationId === parent.id && r.rented)
						: group.reservations.filter((r) => r.parentReservationId === parent.id && !r.rented);

					// Check if any subreservation fully covers the selected period.
					const fullyCovers = subs.some((sub) => {
						const subStart = dateOnly(sub.reservedTimestamp);
						const subEnd = dateOnly(sub.unreservedTimestamp);
						return subStart <= selectedStart && subEnd >= selectedEnd;
					});

					// Determine how many days of the parent's cost should be removed.
					let daysToSubtract = 0;
					if (fullyCovers) {
						daysToSubtract = parentDays;
					}
					else {
						// Sum the days covered by each subreservation.
						subs.forEach((sub) => {
							const subDailyPrice = Number(sub.dailyPrice);
							// Calculate subreservation day count using its totalPriceWithVAT.
							const subDays = Math.round(Number(sub.totalPriceWithVAT) / subDailyPrice);
							daysToSubtract += subDays;
						});
						// Do not subtract more days than the parent's day count.
						if (daysToSubtract > parentDays) {
							daysToSubtract = parentDays;
						}
					}

					// Adjust parent's prices by subtracting the cost for the overlapping days.
					const adjustedParent = { ...parent };
					adjustedParent.totalPriceWithVAT = Number(parent.totalPriceWithVAT) - parentDailyPrice * daysToSubtract;
					adjustedParent.totalPrice = adjustedParent.totalPriceWithVAT / 1.24;
					
					// Now, compute the effective period for the parent.
					// effectiveStart is the later of parent's reservedTimestamp and selectedStart.
					let effectiveStart = dateOnly(parent.reservedTimestamp);
					if (effectiveStart < selectedStart) {
						effectiveStart = new Date(selectedStart);
					}
					// effectiveEnd is the earlier of parent's unreservedTimestamp (or, if null, selectedEnd) and selectedEnd.
					let effectiveEnd = parent.unreservedTimestamp ? dateOnly(parent.unreservedTimestamp) : new Date(selectedEnd);
					if (effectiveEnd > selectedEnd) {
						effectiveEnd = new Date(selectedEnd);
					}

					// Now compute the date gaps if there are subreservations.
					let dateGapStr = "";
					if (subs.length > 0) {
						const gaps = computeDateGaps(effectiveStart, effectiveEnd, subs);
						// Join the gap intervals by a comma and space.
						dateGapStr = gaps.join(", ");
					}
					else {
						// If no subreservations, the entire effective period is used.
						dateGapStr = `${formatDate(effectiveStart)} - ${formatDate(effectiveEnd)}`;
					}
					
					// Store the computed dateGap in new field dateRange.
					adjustedParent.dateRange = dateGapStr;
					
					if (fullyCovers || adjustedParent.totalPriceWithVAT <= 0) {
						adjustedParent.totalPriceWithVAT = 0;
						adjustedParent.totalPrice = 0;
						adjustedParent.covered = true;
					}
					adjustedReservations.push(adjustedParent);
					
					// Order subreservations based on reserved timestamp & Add subreservations as separate rows.
					subs.sort((a, b) => {
						if (a.reservedTimestamp >= b.reservedTimestamp) return 1;
						if (a.reservedTimestamp < b.reservedTimestamp) return -1;
					})
					subs.forEach((sub) => {
						adjustedReservations.push(sub);
					});
				});

				// Recalculate totals for the group.
				const newTotalAmount = adjustedReservations.reduce((sum, r) => sum + Number(r.totalPrice || 0), 0);
				const newTotalAmountWithVAT = adjustedReservations.reduce((sum, r) => sum + Number(r.totalPriceWithVAT || 0), 0);
				
				return {
					...group,
					reservations: adjustedReservations,
					totalAmount: newTotalAmount,
					totalAmountWithVAT: newTotalAmountWithVAT,
				};
			}

			// Process every project group, helper method above
			let processedGroups = grouped.projectGroups.map((group) => processGroup(group));

			processedGroups = processedGroups.sort((a, b) => {
				if (a.projectName === this.$t("tools.rented")) return 1;
				if (b.projectName === this.$t("tools.rented")) return -1;
				return a.projectName.localeCompare(b.projectName);
			});

			// Filter groups based on search query
			if (this.searchQuery) {
				processedGroups = processedGroups.filter((group) => {
					return (
						group.projectName &&
						group.projectName.toLowerCase().includes(this.searchQuery.toLowerCase())
					);
				});
			}

			this.groupedReservationsCalculated = true;
			return {
				...grouped,
				projectGroups: processedGroups,
			};
		},
		selectedProject() {
			const project = this.groupedReservations.projectGroups.find(
				group => group.projectId === this.selectedProjectId
			);

			return project;
		},
		selectEditClass() {
			let darkMode = localStorage.getItem('darkMode');
			// If darkMode does not exist in localStorage, default it to 'false'
			if (darkMode === null) {
				darkMode = 'false';
			}
			if (darkMode === 'false') {
				return 'btn-standard';
			} else {
				return 'btn-primary';
			}
		}
	},
	created() {
		window.addEventListener('resize', this.updateIsMobile);
		this.initializeDateRange();
		this.fetchCostRegistration();
		this.fetchCustomerInformation();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateIsMobile);
	},
	methods: {
		handleReservationEdit(index, row) {
			var emptyUnreservePlaceHolder = 'X';
			var html = "";
			var title = "";
			var confirmButtonText = "";

			if (row.unreservedTimestamp !== null) {
				title = row.parentReservationId ? this.$t('common.subReservation') : this.$t('common.reservation');
				html = `${this.$t('tools.toolReserved')} ${new Date(row.reservedTimestamp).toLocaleDateString('is-IS')} - ${new Date(row.unreservedTimestamp).toLocaleDateString('is-IS')} <br/> ${this.$t('common.totalAmount')} ${this.formatNumber(row.totalPriceWithVAT)} Kr.`;
				confirmButtonText = this.$t('tools.updateReservation');
			}
			else {
				title = this.$t('common.reservation'),
				html = `${this.$t('tools.toolReserved')} ${new Date(row.reservedTimestamp).toLocaleDateString('is-IS')} - ${emptyUnreservePlaceHolder} <br/> ${this.$t('common.totalAmount')} ${this.formatNumber(row.totalPriceWithVAT)} Kr.`;
				confirmButtonText = this.$t('tools.createSubReservation');
			}

			swal.fire({
				title: title,
				html: html,
				icon: 'success',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-danger btn-fill',
					cancelButton: 'btn btn-success btn-fill'
				},
				confirmButtonText: confirmButtonText,
				cancelButtonText: this.$t('delete.cancel'),
				buttonsStyling: false
			}).then(result => {
				if (result.value) {
					this.latestUnreservedId = row.id;
					this.latestUnreservedEntity = row.rented ? 'ExternalReservation' : 'ToolReservation';
					this.createSubReservation = row.unreservedTimestamp ? false : true;
					this.isSubReservation = row.parentReservationId ? true : false;
					this.parentReservationId = row.parentReservationId;

					// We do not allow sub reservation creation outside the selected period 
					// So we set the timestamp as fromDate if it is outside the selected range
					if (this.createSubReservation) {
						this.updatedReservationDate = new Date(row.reservedTimestamp.split('T')[0]) < new Date(this.fromDate) 
							? this.fromDate
							: this.formatDate(row.reservedTimestamp);
					}
					else {
						this.updatedReservationDate = this.formatDate(row.reservedTimestamp);
					}
					
					this.updatedUnreservationDate =  row.unreservedTimestamp ? this.formatDate(row.unreservedTimestamp) : this.toDate;
					this.updatedTotalPrice = row.totalPriceWithVAT;
					this.dailyPriceForUpdate = row.dailyPrice;
					this.quantityForUpdate = row.quantity ?? 1;
					this.showUpdateReservationDaysModal = true;
					this.activeIndex = index
				}
			});
		},
		handleReservationUpdate(){
			if (!this.isValid()) {
				return;
			}

			if (!this.createSubReservation) {
				this.reservationEdit();
			}
			else {
				this.subReservationCreate();
			}
		},
		reservationEdit() {
			let requestBody = {};
			let url = `/Tool/Reservation/${this.latestUnreservedId}`;

			if (this.latestUnreservedEntity === 'ExternalReservation') {
				url = `/Tool/Reservation/External/${this.latestUnreservedId}`;
			}
			requestBody = {
				updatedReservedTimestamp: this.updatedReservationDate,
				updatedUnreservedTimestamp: this.updatedUnreservationDate,
				totalPrice: this.updatedTotalPrice
			};

			api.put(url,requestBody).then(response => {
				this.showUpdateReservationDaysModal = false;
				this.updatePrice = false;
				this.updatedReservationDate = null;
				this.updatedUnreservationDate = null;
				this.dailyPriceForUpdate = null;
				this.quantityForUpdate = null;
				swal.fire({
					title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
					icon: 'success',
					customClass: {
						confirmButton: 'btn btn-success btn-fill'
					},
					buttonsStyling: false
				});
			})
			.catch(error => {
				console.error('API request error:', error);
				this.$notify({
					message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0,
				});
			})
			.finally(() => {
				this.fetchCostRegistration();
			});
		},
		subReservationCreate() {
			let requestBody = {};
			let url = `/Tool/Subreservation`;

			if (this.latestUnreservedEntity === 'ExternalReservation') {
				url = `/Tool/SubReservation/External`;
			}

			requestBody = {
				parentReservationId: this.latestUnreservedId,
				reservedTimestamp: this.updatedReservationDate,
				unreservedTimestamp: this.updatedUnreservationDate,
				totalPrice: this.updatedTotalPrice
			};

			api.post(url,requestBody).then(response => {
				this.showUpdateReservationDaysModal = false;
				this.updatePrice = false;
				this.updatedReservationDate = null;
				this.updatedUnreservationDate = null;
				this.dailyPriceForUpdate = null;
				this.quantityForUpdate = null;
				this.createSubReservation = false;
				this.parentReservationId = null;
				swal.fire({
					title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
					icon: 'success',
					customClass: {
						confirmButton: 'btn btn-success btn-fill'
					},
					buttonsStyling: false
				});
			})
			.catch(error => {
				console.error('API request error:', error);
				this.$notify({
					message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0,
				});
			})
			.finally(() => {
				this.fetchCostRegistration();
			});
		},
		updateTotalPrice() {
			const startDate = new Date(this.updatedReservationDate);
			const endDate = new Date(this.updatedUnreservationDate);
			if (startDate > endDate) {
				this.$notify({
					message: this.$t('errors.startDateBiggerThanEndDate'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0
				});
				return;
			}

			if (endDate === null || startDate === null) {
				this.updatedTotalPrice = 0;
				return;
			}

			// Normalize the dates to midnight (00:00:00)
			startDate.setHours(0, 0, 0, 0);
			endDate.setHours(0, 0, 0, 0);

			// Calculate the difference in time (milliseconds)
			const timeDiff = endDate.getTime() - startDate.getTime();

			// Calculate the total number of days
			const totalDays = timeDiff / (1000 * 3600 * 24) + 1;

			// Round up to the nearest whole day
			const totalDaysRounded = Math.ceil(totalDays);

			this.updatedTotalPrice = Math.ceil(totalDaysRounded * this.dailyPriceForUpdate * this.quantityForUpdate);
		},
		isValid() {
			if (this.updatedReservationDate === null || this.updatedReservationDate === '' || this.updatedUnreservationDate === null || this.updatedUnreservationDate === '') {
				this.$notify({
					message: this.$t('errors.datesEmptyOrIncorrect'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0
				});
				return false;
        	}

			if (!this.isValidDate(this.updatedReservationDate) || !this.isValidDate(this.updatedUnreservationDate)) {
				this.$notify({
					message: this.$t('errors.datesEmptyOrIncorrect'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0
				});
				return false;
			}

			if (new Date(this.updatedReservationDate) > new Date(this.updatedUnreservationDate)) {
				this.$notify({
					message: this.$t('errors.startDateBiggerThanEndDate'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0
				});
				return false;
			}

			if (!Number.isInteger(Number(this.updatedTotalPrice)) || this.updatedTotalPrice === '') {
				this.$notify({
					message: this.$t('common.needsToBeNumber'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0,
				});
				return false;
			}

			if (this.isSubReservation || this.createSubReservation) {
				if (new Date(this.updatedReservationDate) < new Date(this.fromDate) || new Date(this.updatedUnreservationDate) > new Date(this.toDate)) {
					this.$notify({
						message: this.$t('errors.subReservationBiggerThanDateRange'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
					return false;
				}

				var projectReservations = this.selectedProject.reservations;
				
				const newStart = new Date(this.updatedReservationDate);
  				const newEnd = new Date(this.updatedUnreservationDate);
				var subReservations = this.latestUnreservedEntity === 'ToolReservation' ?
					projectReservations.filter(
						r => this.createSubReservation ? r.parentReservationId === this.latestUnreservedId && !r.rented : r.parentReservationId === this.parentReservationId && r.id !== this.latestUnreservedId && !r.rented
					)
					: projectReservations.filter(
						r => this.createSubReservation ? r.parentReservationId === this.latestUnreservedId && r.rented : r.parentReservationId === this.parentReservationId && r.id !== this.latestUnreservedId && r.rented
					);

				// Make sure sub reservations do not overlap
				for (let sub of subReservations) {
					const linkedSubStart = new Date(sub.reservedTimestamp);
					const linkedSubEnd = new Date(sub.unreservedTimestamp);
					if (newStart <= linkedSubEnd && newEnd >= linkedSubStart) {
						this.$notify({
							message: this.$t('errors.subReservationsOverlap'),
							icon: "tim-icons icon-alert-circle-exc",
							horizontalAlign: "center",
							verticalAlign: "top",
							type: "danger",
							timeout: 0,
						});
						return false;
					}
				}

				if (this.isSubReservation) {
					var parentReservation = this.latestUnreservedEntity === 'ToolReservation' ?
						projectReservations.find(
							r => r.id === this.parentReservationId && !r.rented
						)
						: projectReservations.find(
							r => r.id === this.parentReservationId && r.rented
						);
					const parentStart = new Date(parentReservation.reservedTimestamp.split("T")[0]);
					const parentEnd = new Date(this.toDate);
					
					if (newStart < parentStart || newEnd > parentEnd) {
						this.$notify({
							message: this.$t('errors.subReservationBiggerThanParent'),
							icon: "tim-icons icon-alert-circle-exc",
							horizontalAlign: "center",
							verticalAlign: "top",
							type: "danger",
							timeout: 0,
						});
						return false;
					}
				}
			}
			return true;
		},
		isValidDate(dateString) {
			const date = new Date(dateString);
			return date instanceof Date && !isNaN(date);
		},
		formatNumber(num) {
			if (num === null || num === undefined) return '';
			
			const roundedNumber = Math.round(num);
			const fixedNumber = roundedNumber.toFixed(2);
			const parts = fixedNumber.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		expandProject(row) {
			this.selectedProjectId = row.projectId;
			this.expandedProject = row;
			this.showProjectDetails = true;
			window.scrollTo(0, 0);
		},
		collapseProject() {
			this.fetchCostRegistration();
			this.expandedProject = null;
			this.selectedProjectId = null;
			this.showProjectDetails = false;
		},
		initializeDateRange() {
			const today = new Date();
			this.toDate = this.formatDate(today);
			this.fromDate = this.formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
		},
		formatDate(date) {
			let d = new Date(date);
			let year = d.getFullYear();
			let month = String(d.getMonth() + 1).padStart(2, '0');
			let day = String(d.getDate()).padStart(2, '0');

			return `${year}-${month}-${day}`;
		},
		fetchCostRegistration() {
			const costReportUrl = `/CostReport/Reservations?fromDate=${this.fromDate}&toDate=${this.toDate}`;
			api.get(costReportUrl)
				.then(response => {
					this.reservationData = response.data;
					this.costRegistrationLoaded = true;
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: this.$t('common.unableToRetrieveData'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 2000,
					});
				});
		},
		setupDateRange(resTimestamp, unresTimestamp, dateRange) {
			if (dateRange) {
				return dateRange;
			}
			var dateDisplay = resTimestamp < this.fromDate ? this.formatDateDisplay(this.fromDate) : this.formatDateDisplay(resTimestamp);
			dateDisplay += unresTimestamp <= this.toDate ? ` - ${this.formatDateDisplay(unresTimestamp) }` : ` - ${this.formatDateDisplay(this.toDate) }`
			return dateDisplay;
		},
		formatDateDisplay(date) {
			//This is used when displaying the reservation period after you select a project
			let d = new Date(date);
			let year = d.getFullYear();
			let month = String(d.getMonth() + 1).padStart(2, '0');
			let day = String(d.getDate()).padStart(2, '0');

			return `${day}/${month}/${year}`;
		},
		fetchCustomerInformation() {
			let customerUrl = `/Customers/Me`
			api.get(customerUrl)
				.then(response => {
					this.customerInformation = response.data.value;
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: this.$t('common.unableToRetrieveData'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 2000,
					});
				});
		},
		generatePDF(project) {
			const doc = new jsPDF();
			doc.setFont('times', 'normal');
			const textXPosition = 65;

			// This whole if else if crap should be revisited, is a bit retarded how I am doing this now tbh
			if (this.customerInformation.logoBytes === null && this.customerInformation.identificationNumber === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(project.projectName, textXPosition, 30);
				doc.text(project.projectKey, textXPosition, 40);
				generatePdfBody(project, doc);
			}
			else if (this.customerInformation.logoBytes === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
				doc.setFontSize(10);
				doc.text(project.projectName, textXPosition, 40);
				doc.text(project.projectKey, textXPosition, 50);
				this.generatePdfBody(project, doc);
			}
			else if (this.customerInformation.identificationNumber === null) {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					const targetSize = 50;
					const imgWidth = img.width;
					const imgHeight = img.height;
					// Calculate the scaling factor to maintain aspect ratio
					const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// Calculate the new width and height to maintain aspect ratio 
					const width = imgWidth * scale;
					const height = imgHeight * scale; 
					
					// Calculate the positions to center the image if it's smaller than 50x50 
					const xOffset = (targetSize - width) / 2;
					const yOffset = (targetSize - height) / 2;
					// Add the image with the calculated dimensions, centered within the 50x50 box 
					doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(project.projectName, textXPosition, 30);
					doc.text(project.projectKey, textXPosition, 40);
					this.generatePdfBody(project, doc);
				}
				img.onerror = (e) => { 
					console.error("Image failed to load");
					// Display an error message to the user 
					this.$notify({
						message: ` ${this.$t('errors.companyPhoto')}`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				};
			}
			else {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					const targetSize = 50;
					const imgWidth = img.width;
					const imgHeight = img.height;
					// Calculate the scaling factor to maintain aspect ratio
					const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// Calculate the new width and height to maintain aspect ratio 
					const width = imgWidth * scale;
					const height = imgHeight * scale; 
					
					// Calculate the positions to center the image if it's smaller than 50x50 
					const xOffset = (targetSize - width) / 2;
					const yOffset = (targetSize - height) / 2;
					// Add the image with the calculated dimensions, centered within the 50x50 box 
					doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
					doc.setFontSize(10);
					doc.text(project.projectName, textXPosition, 40);
					doc.text(project.projectKey, textXPosition, 50);
					this.generatePdfBody(project, doc);
				}
				img.onerror = (e) => { 
					console.error("Image failed to load");
					// Display an error message to the user 
					this.$notify({
						message: ` ${this.$t('errors.companyPhoto')}`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				};
			}
		},
		generatePdfBody(project, doc) {
		doc.setFontSize(10);

		// Prepare the headers
		const headers = [
			[
			{ content: 'Tól', styles: { halign: 'left' } },
			{ content: 'Fjöldi', styles: { halign: 'center' } },
			{ content: 'Einingarverð', styles: { halign: 'center' } },
			{ content: 'Verð', styles: { halign: 'right' } },
			{ content: 'Verð m/vsk', styles: { halign: 'right' } }
			]
		];
		// Prepare the data
		const body = [];
		const footer = [];
		const colSpan = headers[0].length;

		// Start with an empty line to separate from the table body from the header a bit better
		body.push([
			{
			content: '',
			colSpan: colSpan,
			styles: { halign: 'left', cellPadding: 0.1 }
			}
		]);

		const groupedByTools = project.reservations.reduce((acc, reservation) => {
			if (!acc[reservation.toolId]) {
				acc[reservation.toolId] = {
					tool: reservation.tool,
					reservations: []
				};
			}
			acc[reservation.toolId].reservations.push(reservation);
			return acc;
		}, {});

		const toolsArray = Object.keys(groupedByTools).map(toolId => groupedByTools[toolId]);

		toolsArray.forEach(tool => {
			// Add a tool name row as a section header
			var toolBody = [];
			var printTool = false;
			toolBody.push([
			{
				content: tool.tool.name,
				colSpan: colSpan,
				styles: { halign: 'left' }
			}
			]);

			tool.reservations.forEach(reservation => {
			if (reservation.totalPrice !== 0) {
				printTool = true;
				const dateDisplay = this.setupDateRange(reservation.reservedTimestamp, reservation.unreservedTimestamp, reservation.dateRange);
				const unitPrice = this.formatNumber(reservation.dailyPrice.toFixed(2));
				const quantity = reservation.quantity !== null ? reservation.quantity.toString() : '1';
				const priceCalculation = this.formatNumber(reservation.totalPrice.toFixed(2)) + ' kr';
				const priceWithVAT = this.formatNumber(reservation.totalPriceWithVAT.toFixed(2)) + ' kr';

				toolBody.push([
				{ content: `    ${dateDisplay}`, styles: { halign: 'left' } },
				{ content: quantity, styles: { halign: 'center' } },
				{ content: unitPrice, styles: { halign: 'center' } },
				{ content: priceCalculation, styles: { halign: 'right' } },
				{ content: priceWithVAT, styles: { halign: 'right' } }
				]);
			}
			});

			if (printTool) {
			toolBody.push([
				{
				content: '',
				colSpan: colSpan,
				styles: { halign: 'left' }
				}
			]);
			body.push(...toolBody);
			}
		});

		// Add the total amounts
		const revenueWithoutVAT = this.formatNumber(project.totalAmount.toFixed(2)) + ' kr';
		const totalWithVAT = this.formatNumber(project.totalAmountWithVAT.toFixed(2)) + ' kr';

		// This uses a customized colSpan to make sure rev and total is displayed in the correct columns
		footer.push([
			{ content: 'Heildarupphæð', colSpan: 3, styles: { halign: 'left', fontStyle: 'bold' } },
			{ content: revenueWithoutVAT, styles: { halign: 'right' } },
			{ content: totalWithVAT, styles: { halign: 'right' } }
		]);

		// Generate the table using autoTable
		autoTable(doc, {
			head: headers,
			body: body,
			foot: footer,
			startY: 70, // Adjust starting Y position based on your layout
			theme: 'plain', // Use 'plain' for no borders
			styles: {
			textColor: 0,
			},
			headStyles: {
			cellPadding: 3,
			fillColor: [255, 255, 255],
			fontStyle: 'bold',
			lineWidth: {
				top: 0.2,
				bottom: 0.2
			},
			lineColor: [0, 0, 0],
			},
			bodyStyles: {
			cellPadding: 1,
			fontSize: 10,
			},
			footStyles: {
			fillColor: [255, 255, 255],
			lineWidth: {
				top: 0.2,
			},
			lineColor: [0, 0, 0],
			},
			showHead: 'firstPage',
			showFoot: 'lastPage',
			columnStyles: {
			0: { cellWidth: 54 }, // Tól
			1: { cellWidth: 16 }, // Fjöldi
			2: { cellWidth: 30 }, // Einingarverð
			3: { cellWidth: 40 }, // Verð
			4: { cellWidth: 40 }  // Verð m/vsk
			}
		});

		// Save the PDF
		doc.save(`${project.projectName}.pdf`);
		},
		updateIsMobile() {
			this.isMobile = window.innerWidth <= 768;
		}
	}
};
</script>
<style scoped>
	.cost-report { 
		width: 100%; border-collapse: collapse;
	}
	.header-row, .project-row, .footer-row, .details {
		display: flex;
	}
	.header-row {
		border-bottom: 1px solid #ddd;
	}
	.footer-row {
		border-top: 1px solid #ddd;
	}
	.cell { 
		flex: 1; padding: 8px; text-align: left; cursor: pointer;
	} 
	.tool-row .cell { 
		cursor: default;
	}
	.calendar {
		padding-bottom: 15px;
	}
	.item-tool {
		padding-left: 20px;
		margin-bottom: 10px;
	}

	.item-tool-dates {
		padding-left: 40px;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.item-tool-dates-amount {
		float: right;
		padding-right: 50px;
	}

	.download-button {
		width: 100%
	}

	.project-details { 
		display: block;
	}
	.newline { 
		display: block;
	}
</style>
